import React from 'react'
import DefaultKey from './computer/keyboard/keys'

export default function DefaultProject({ loop }) {
	return (
		<div className="proj-root" style={{userSelect: 'none'}}>
			<div className="proj-select-container">
				<h1
					className="proj-select"
					style={{ fontWeight: '600' }}
				>
					Select A{' '}
					<span className="underline-blue">
						Project
					</span>
				</h1>
				<DefaultKey
                    className="proj-key"
                    size={100}
					vectorClass="proj-key-vector"
                    text="Press"
                    accessibility='Press a key on the keyboard to select a project'
                    content={null}
                    loop={loop}
                    disable
				/>
			</div>
		</div>
	)
}
