import React, { useEffect, useState, useLayoutEffect } from 'react'
import SEO from 'Components/seo'
import 'SCSS/experience.scss'
import Tv from './components/computer/television'
import Navbar from 'Components/navbar'
import Keyboard from './components/computer/keyboard/keyboard'
import { graphql } from 'gatsby'
import DefaultProject from './components/default'
import styled from 'styled-components'
import { gsap } from 'gsap/gsap-core'
import { enterExperience } from 'Utils/animations/transitions'
import { navigate } from '@reach/router'
import { parameterize, updateParameters } from 'Utils/tools/parameterize'
import XPToggle, { togglePaths } from './components/toggle'
import PreviewGrid from './components/preview/preview-grid'
import 'SCSS/project.scss'
import { useRef } from 'react'
import ProjectTemplate from 'Templates/project-templates/project-template'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import FSToggle from 'Utils/animations/fullscreen'
import FullscreenIcon from 'Assets/svgs/fullscreen-icon'
import {isMobile} from 'react-device-detect'

// For build
const Snap = typeof window !== `undefined` ? require('snapsvg') : null

const shortcodes = { ProjectTemplate }

const ExperiencePage = props => {
	// === Data === //
	const keyEdges = props.data.allKeyboardJson.edges
	const imageEdges = props.data.allFile.edges
	const pagesEdges = props.data.allMdx.edges

	// === State Objects === //
	// For page data
	const [page, setPage] = useState({
		dir: null,
		title: null,
		mdx: null,
		frontmatter: null,
	})
	// For animations
	const [timeline, setTimeline] = useState(gsap.timeline())
	const [fullscreen, setFullscreen] = useState(null);

	// === Variables === //
	let { proj, view } = parameterize(props.location.search)
	const [isGalleryMode, setIsGalleryMode] = useState(view === '1')
	let isGallery = view === '1' || isMobile
	let enableFullscreen = true;

	//    ______  __  __          _
	//   |  ____|/ _|/ _|        | |
	//   | |__  | |_| |_ ___  ___| |_
	//   |  __| |  _|  _/ _ \/ __| __|
	//   | |____| | | ||  __/ (__| |_
	//   |______|_| |_| \___|\___|\__|
	//
	//

	useEffect(() => {
		// Setup the animation timeline
		setTimeline(gsap.timeline())

		// === URL Load === //
		// Below loads the page from a given URL. This is meant to allow
		// custom URLs to be loaded and for their respective projects
		// to appear.
		loadView(view === '1' || isMobile)

		// IF: project param in URL
		if (proj) {
			let pageData = props.data.allMdx.edges.find(
				edge => edge.node.frontmatter.project === proj
			)
			// IF: page exists
			if (pageData) {
				setPage({
					dir: pageData.node.frontmatter.project,
					title: pageData.node.frontmatter.title,
					mdx: pageData.node.body,
					frontmatter: pageData.node.frontmatter,
				})
			} else {
				// ELSE: Load default
				loadDefault()
			}
		} else {
			loadDefault()
		}
		// /== URL Load === //

		function escapeToDefault(e) {
			if (e.code === 'Escape') {
				loadDefault()
			}
		}

		window.addEventListener('keydown', escapeToDefault, false)

		return () => {
			window.removeEventListener(
				'keydown',
				escapeToDefault,
				false
			)
		}
	}, [])

	useLayoutEffect(() => {
		// Setup fullscreen toggle
		
		let elt = document.querySelector(`#${'screen-content'}`)
		if(elt && !fullscreen) {
			setFullscreen(new FSToggle(elt, '.xp-full-view', {zIndex: 1000, duration: 1}));
		}
		
	}, [])

	useEffect(() => {
		if(isGallery !== isGalleryMode) {
			setURL(page, true)
		}
	}, [isGalleryMode])

	//    _                     _
	//   | |                   | |
	//   | |     ___   __ _  __| | ___ _ __
	//   | |    / _ \ / _` |/ _` |/ _ \ '__|
	//   | |___| (_) | (_| | (_| |  __/ |
	//   |______\___/ \__,_|\__,_|\___|_|
	//
	//

	// === PAGE LOADER === //
	const loadPage = page => {
		// Update DOM
		setPage(page)

		// Update URL
		setURL(page)
		
	}
	// --- Loads Default Page --- //
	function loadDefault() {
		if (getPageTitle().hasOwnProperty('proj')) {
			setPage({
				dir: null,
				title: null,
				mdx: null,
				frontmatter: null,
			})
			setURL({ title: null })
		}
		onGalleryShrink()
	}

	function getPageTitle() {
		return parameterize(props.location.search)
	}

	function setURL(pageData=page, forceUpdate=false) {
		if (pageData.dir !== page.dir || pageData.dir === null || forceUpdate) {
			let query = updateParameters(props.location.search, {
				proj: pageData.dir,
				view: isGalleryMode,
			})
			if (query) {
				props.location.search = query
			} else {
				props.location.search = ''
				query = '/experience/'
			}
			navigate(query, { replace: true })
		}
	}

	const getScreen = () => (
			<Screen id='screen-content'>
				{
					page.title !== null ? (
							<MDXProvider components={shortcodes}>
								<MDXRenderer>{page.mdx}</MDXRenderer>
							</MDXProvider>
						) : ( 	
							<DefaultProject loop={!proj} />
						)
				}
			</Screen>
		)

	//                   _                 _
	//       /\         (_)               | |
	//      /  \   _ __  _ _ __ ___   __ _| |_ ___
	//     / /\ \ | '_ \| | '_ ` _ \ / _` | __/ _ \
	//    / ____ \| | | | | | | | | | (_| | ||  __/
	//   /_/    \_\_| |_|_|_| |_| |_|\__,_|\__\___|
	//
	//

	function resetTimeline() {
		if (timeline) {
			// Go to end of timeline, clear, then restart
			timeline.seek(timeline.endTime())
			timeline.clear()
			timeline.restart()
		}
	}

	function loadView(gallery=false) {
		// === Toggle Button === //
		var togglePath = Snap('#toggleViewButton').select(
			'#toggleViewButtonPath'
		)
		// === SWAP === //
		resetTimeline();
		if(gallery) {
			// --- Toggle Button --- //
			togglePath.animate(
				{d: togglePaths.keyboard},
				0
			)
			// --- Set States --- //
			isGallery = true
			setIsGalleryMode(true);
			loadDefault()
			view = '1'
			// --- Hide Computer --- //
			timeline.to('.xp-computer-view', {opacity: 0, display: 'none', duration: 0})
			.to('.xp-gallery-container', {opacity: 1, display: null, duration: 0})
		} else {
			// --- Toggle Button --- //
			togglePath.animate(
				{d: togglePaths.gallery},
				0
			)
			// --- Set States --- //
			isGallery = false
			setIsGalleryMode(false);
			loadDefault()
			view = '0'
			// --- Hide Computer --- //
			timeline.to('.xp-gallery-container', {opacity: 0, display: 'none', duration: 0})
			.to('.xp-computer-view', {opacity: 1, display: null, duration: 0})
		}

		setURL();
	}

	function swapView(e, immediate = false) {
		// === Change Toggle Button === //
		var togglePath = Snap('#toggleViewButton').select(
			'#toggleViewButtonPath'
		)

		const easeInOut = t =>
			t < 0.5
				? 4 * t * t * t
				: (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
		const toggleDur = 750
		const swapDur = 1
		const totalProjects = document.querySelectorAll('.xp-project')
			.length

		if (isGallery) {
			// === Toggle To Computer === //
			togglePath.animate(
				{ d: togglePaths.gallery },
				immediate ? 0 : toggleDur,
				easeInOut
			)
			isGallery = false
			setIsGalleryMode(false);
			loadDefault()
			view = '0'

			// === Animate To Computer === //

			resetTimeline()
			if (!immediate) {
				timeline.to('.project-preview', {
					opacity: 0,
					stagger: {
						amount: swapDur,
						from: "random",
						ease: 'none'
					}
				})
					.to('.xp-gallery-container', {
						opacity: 0,
						display: 'none',
						duration: 0.1,
					})
					.fromTo(
						'.xp-computer-view',
						{ opacity: 0, display: 'none' },
						{
							opacity: 1,
							display: null,
							duration: 0.1,
						}
					)
					.fromTo(
						'#screen-content',
						{ opacity: 0, display: 'none' },
						{
							opacity: 1,
							display: null,
							duration: 0.1,
						}
					)
					.add(
						enterExperience({
							node: document,
							from: '/experience',
							duration: 2,
						}).eventCallback(
							'onStart',
							() => {
								// Make sure TV is right size (defined during resize event)
								let resizeEvent = window.document.createEvent(
									'UIEvents'
								)
								resizeEvent.initUIEvent(
									'resize',
									true,
									false,
									window,
									0
								)
								window.dispatchEvent(
									resizeEvent
								)
							}
						)
					)
			} else {
				document.querySelector(
					'.xp-computer-view'
				).style.display = null
				document.querySelector(
					'.xp-gallery-view'
				).style.display = 'none'
			}
		} else {
			// === Toggle To Gallery === //
			togglePath.animate(
				{ d: togglePaths.keyboard },
				immediate ? 0 : toggleDur,
				easeInOut
			)
			isGallery = true
			setIsGalleryMode(true);
			loadDefault()
			view = '1'

			// === Animate To Gallery === //
			if (!immediate) {
				let gallery = document.querySelector('.xp-gallery-view')
				resetTimeline()
				if(fullscreen && fullscreen.isFullscreen) {
					timeline.pause();
					toggleFullscreen(null, () => {timeline.resume()});
				}
				timeline.to('.xp-computer-view', {
					opacity: 0,
					display: 'none',
					duration: swapDur,
					ease: 'power4.out',
				})
					.to('.xp-gallery-container', {
						opacity: 1,
						display: null,
						duration: 0,
					})
					.fromTo(
						gallery.children,
						{ y: '-50%', opacity: 0 },
						{
							y: '0%',
							opacity: 1,
							duration: swapDur,
							stagger: Math.min(
								0.15,
								swapDur /
									totalProjects
							),
							ease: 'expo.out',
						}
					)
			} else {
				document.querySelector(
					'.xp-computer-view'
				).style.display = 'none'
				document.querySelector(
					'.xp-gallery-container'
				).style.display = null
			}
		}

		// Update URL
		if (!immediate) {
			setURL()
		}
	}

	function toggleFullscreen(_e, fn) {
		fullscreen.toggle(fn);
	}

	const [galleryIsExpanded, setGalleryIsExpanded] = useState(false)
	const galleryGridRef = useRef();
	function onGalleryExpand() {
		setGalleryIsExpanded(true);
	}
	function onGalleryShrink() {
		setGalleryIsExpanded(false);
		galleryGridRef.current.shrink(page.title !== null ? page.title : '');
	}

	//    _____                _
	//   |  __ \              | |
	//   | |__) |___ _ __   __| | ___ _ __
	//   |  _  // _ \ '_ \ / _` |/ _ \ '__|
	//   | | \ \  __/ | | | (_| |  __/ |
	//   |_|  \_\___|_| |_|\__,_|\___|_|
	//
	//

	return (
		<div className="page">
			<SEO title="Experience" url='/experience' description='Projects and relevant experience for Gino Valente' />
			<Navbar from="/experience" />
			<div className={'page-main xp-page'}>
				<div className='xp-btns'>
					<XPToggle
						onMouseDown={swapView}
						className={'xp-btns-mode'}
					/>
					{galleryIsExpanded && <FullscreenIcon reverse onClick={e => onGalleryShrink()} className='xp-btns-fullscreen' />}
					{(enableFullscreen && !isGallery) && <FullscreenIcon onClick={toggleFullscreen} className='xp-btns-fullscreen' />}
				</div>
				<div className='xp-full-view' />
				<div className="xp-computer-view">
					<Tv
						style={{
							margin: '1% 0 1% 0',
							height: '65%',
							minHeight: '350px',
							minWidth: '100px',
						}}
						ratio={1.85}
						className="xp-computer"
					>
						{getScreen()}
					</Tv>
					<Keyboard
						style={{
							width: '100%',
							margin: '0 auto',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
						keyMap={keyEdges}
						imageMap={imageEdges}
						pagesMap={pagesEdges}
						loader={loadPage}
					/>
				</div>
				<PreviewGrid 
					containerClassName={'xp-gallery-container'}
					className={'xp-gallery-view'}
					style={{display: 'none'}}
					projects={pagesEdges}
					open={isGallery ? page.title : ''}
					ref={galleryGridRef}
					onExpand={e => onGalleryExpand()}
				/>
				{/* <PreviewGrid
					ref={grid}
					style={{ display: 'none' }}
					className={'xp-gallery-view'}
					itemClass={'xp-project'}
					projectEdges={pagesEdges}
					imageMap={imgMap}
					loader={loadPage}
					location={props.location}
					current={page.title}
				/> */}
			</div>
		</div>
	)
}

//     ____
//    / __ \
//   | |  | |_   _  ___ _ __ _   _
//   | |  | | | | |/ _ \ '__| | | |
//   | |__| | |_| |  __/ |  | |_| |
//    \___\_\\__,_|\___|_|   \__, |
//                            __/ |
//                           |___/

export const keyboardQuery = graphql`
	query keyQuery {
		allKeyboardJson {
			edges {
				node {
					id
					row {
						code
						size
						text
						project
					}
				}
			}
		}
		allFile(
			filter: {
				extension: { in: ["jpg", "jpeg", "png", "gif"] }
				sourceInstanceName: { eq: "projects" }
			}
		) {
			edges {
				node {
					id
					base
					name
					publicURL
					relativeDirectory
				}
			}
		}
		allMdx(
			filter: { fileAbsolutePath: { regex: "/projects/" } }
			sort: { fields: frontmatter___title }
		) {
			edges {
				node {
					id
					body
					frontmatter {
						project
						title
						type
						image {
							childImageSharp {
								fluid(
									maxWidth: 200
								) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
`

//     _____                                             _
//    / ____|                                           | |
//   | |     ___  _ __ ___  _ __   ___  _ __   ___ _ __ | |_ ___
//   | |    / _ \| '_ ` _ \| '_ \ / _ \| '_ \ / _ \ '_ \| __/ __|
//   | |___| (_) | | | | | | |_) | (_) | | | |  __/ | | | |_\__ \
//    \_____\___/|_| |_| |_| .__/ \___/|_| |_|\___|_| |_|\__|___/
//                         | |
//                         |_|

const Screen = styled.div`
	height: 100%;
	width: 100%;
`

export default ExperiencePage