import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'

export default function AspectRatio({ auto=false, maintainHeight=false, ratio=1, children, ...etc }) {
    const ref: React.RefObject<HTMLDivElement> = useRef()
    let autoRatio: number = null

	useEffect(() => {
        // === Initial Sizing === //
		maintain()

        // === Size on Resize === //
		window.addEventListener('resize', maintain, false)

		// === Clean Up === //
		return () => {
			window.removeEventListener('resize', maintain, false)
		}
	}, [])

	function maintain() {
		if (ref && ratio > 0) {
            if(auto) {
                // === Setup Ratio === //
                if (autoRatio === null) {
                    autoRatio = ref.current.clientWidth / ref.current.clientHeight
                    ratio = autoRatio
                }
                
            }
            
            if(maintainHeight) {
                let height: number = ref.current.clientHeight;
                let width: number = height * ratio;
                ref.current.style.width = `${width}px`
            } else {
                let width: number = ref.current.clientWidth;
                let height: number = width / ratio;
                ref.current.style.height = `${height}px`
            }
		}
	}

	return (
		<div ref={ref} {...etc}>
			{children}
		</div>
	)
}
