import React from 'react'
import styles from './popup.module.scss'

export const KeyPop = React.forwardRef((props, ref) => {
    return (
        <div className={styles.popWrapper} style={props.style} {...props}>
            <div ref={ref} className={styles.popContainer}>
                <div className={styles.popContent}>{props.children}</div>
                <h2 className={styles.popCaption}>{props.title}</h2>
            </div>
        </div>
    )
})
