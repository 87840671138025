import React, { useRef } from 'react'
import styled from 'styled-components'
import { createRef } from 'react'
import { useEffect } from 'react'
import { KeyPop } from './popup'
import styles from './keys.module.scss'
import { useState } from 'react'
import nextId from 'react-id-generator'
import { smartTimeline } from 'Utils/animations/control'

const KeyContainer = styled.div`
	height: 100%;
	position: relative;
`

const KeyVector = styled.svg`
	height: 100%;
	width: auto;
	display: block;
	vector-effect: non-scaling-stroke;
	cursor: pointer;
`

const KeyText = styled.text`
	white-space: pre;
	fill: white;
	space: preserve;
	letter-spacing: 0em;
	font-size: 100%;
	text-anchor: middle;
	dominant-baseline: middle;
	transform: rotateX(25deg);
`

export default function DefaultKey({
	text,
	eventCode,
	size = 100,
	height = 100,
    title = ' ',
    accessibility = null,
	content = null,
	onSelect = () => {},
	randomColor = false,
	vectorClass,
    loop = false,
    disable = false,
	...etc
}) {
	// === CONFIG === //
	const DUR = 0.5
	const WIDTH = size
	const HEIGHT = height
	const BREAK = HEIGHT * 0.65
	const BACK = HEIGHT * 0.15
	const FACE_BR = HEIGHT * 0.25
	const SHRUNK = HEIGHT * 0.45
	const pressed = `M 10 ${BACK} L ${WIDTH - 10} ${BACK} L ${WIDTH -
		5} ${BREAK} L 5 ${BREAK} L 10 ${BACK} M 10 ${BACK} L 5 ${FACE_BR} L 0 ${HEIGHT} L 5 ${BREAK} L 10 ${BACK} M 5 ${BREAK} L ${WIDTH -
		5} ${BREAK} L ${WIDTH} ${HEIGHT} L 0 ${HEIGHT} L 5 ${BREAK} M ${WIDTH -
		5} ${BREAK} L ${WIDTH} ${HEIGHT} L ${WIDTH -
		5} ${FACE_BR} L ${WIDTH - 10} ${BACK} L ${WIDTH - 5} ${BREAK}`
	const released = `M 10 0 L ${WIDTH - 10} 0 L ${WIDTH -
		5} ${SHRUNK} L 5 ${SHRUNK} L 10 0 M 10 0 L 5 ${FACE_BR} L 0 ${HEIGHT} L 5 ${SHRUNK} L 10 0 M 5 ${SHRUNK} L ${WIDTH -
		5} ${SHRUNK} L ${WIDTH} ${HEIGHT} L 0 ${HEIGHT} L 5 ${SHRUNK} M ${WIDTH -
		5} ${SHRUNK} L ${WIDTH} ${HEIGHT} L ${WIDTH -
		5} ${FACE_BR} L ${WIDTH - 10} 0 L ${WIDTH - 5} ${SHRUNK}`

	// === REFS === //
	const [keyPath] = useState(createRef())
    const [textRef] = useState(createRef())

	let popRef = createRef()

	// === MEMBERS === //
	let isOpen = false

	const fillClass = content !== null ? styles.addFill : ''
	const Thumbnail = content ? (
		<KeyPop ref={popRef} title={title}>
			{content}
		</KeyPop>
	) : (
		''
    )

	const pressLoop = () => {
        let loopDur = DUR * 2
        let timeline = smartTimeline()
        timeline.repeat(-1)
        timeline.repeatDelay(loopDur * 1.5)
        .add(pressKey)
        .add(releaseKey, loopDur/2)
        .add(() => {
            if(!keyPath.current || !textRef.current) {
                timeline.repeat(0)
            }
        })
	}

	// === METHODS === //
	const pressKey = () => {
        if(!keyPath.current || !textRef.current) {return;}
        
		let timeline = smartTimeline()
		timeline.to(keyPath.current, {
			attr: { d: pressed },
			duration: DUR,
			ease: 'expo.out',
		})
		timeline.to(
			textRef.current,
			{ attr: { y: '38%' }, duration: DUR, ease: 'expo.out' },
			`-=${DUR}`
        )
        
		if (popRef.current && !timeline.paused()) {
			timeline.to(
				popRef.current,
				{
					transform: 'scale(1)',
					duration: DUR,
					ease: 'expo.out',
				},
				`-=${DUR}`
			)
		} else if (popRef.current) {
            popRef.current.style.transform = 'scale(1)'
        }

        isOpen = true

		return timeline
	}

	const releaseKey = () => {
        if(!keyPath.current || !textRef.current) {return;}
		let timeline = smartTimeline()
		timeline.to(keyPath.current, {
			attr: { d: released },
			duration: DUR,
			ease: 'bounce.out',
		})
		timeline.to(
			textRef.current,
			{
				attr: { y: '22%' },
				duration: DUR,
				ease: 'bounce.out',
			},
			`-=${DUR}`
		)
		if (popRef.current  && !timeline.paused()) {
			timeline.to(
				popRef.current,
				{
					transform: 'scale(0)',
					duration: DUR,
					ease: 'expo.out',
				},
				`-=${DUR}`
			)
		} else if (popRef.current) {
            popRef.current.style.transform = 'scale(0)'
        }

		isOpen = false

		return timeline
	}

	const selectKey = e => {
		releaseKey()
		onSelect(eventCode)
	}

	// === LISTENERS === //
	const listenForPress = e => {
		if (e.code === eventCode) {
			if (isOpen) {
				selectKey()
			} else {
				pressKey()
			}
		} else {
			releaseKey()
		}
	}
	const clickOut = e => {
		if (isOpen) {
			releaseKey()
		}
	}

	useEffect(() => {
		if(loop){	
			pressLoop()
		}
	}, [loop])

	useEffect(() => {
		if (eventCode) {
			window.addEventListener(
				'keydown',
				listenForPress,
				false
			)
			window.addEventListener('mousedown', clickOut, false)
		}

		return () => {
			window.removeEventListener(
				'keydown',
				listenForPress,
				false
			)
			window.removeEventListener('mousedown', clickOut, false)
		}
	})

	let col
	if (fillClass.length === 0 && randomColor) {
		let colLightness = 120
		let colAlpha = 0.5
		col = `rgba(${Math.random() * colLightness}, ${Math.random() *
			colLightness}, ${Math.random() *
			colLightness}, ${colAlpha})`
    }
    
    let accessibleTitleId = nextId('key-title--')

	// === OUTPUT === //
	return (
		<KeyContainer data-code={eventCode} {...etc}>
			{/* <div style={{position: "relative", height: '100%'}}> */}
			{/* <KeyHover style={{height: '100%', top: 0, left: 0, right: 0}} onMouseOver={pressKey} onFocus={pressKey} onMouseLeave={releaseKey} onMouseDown={selectKey}> */}
			<KeyVector
				onMouseOver={!disable ? pressKey : ()=>{}}
				onFocus={!disable ? pressKey : ()=>{}}
				onMouseLeave={!disable ? releaseKey : ()=>{}}
				onMouseDown={!disable ? selectKey : ()=>{}}
				style={{ fill: col }}
				className={`${styles.keyVector} ${fillClass} ${vectorClass}`}
				width={WIDTH}
				height={HEIGHT}
				viewBox={`-5 -5 ${10 + WIDTH} ${10 + HEIGHT}`}
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
                xmlns="http://www.w3.org/2000/svg"
                aria-labelledby={accessibleTitleId}

			>
                <title id={accessibleTitleId}>{accessibility ? accessibility : (title ? `Press to select ${title}` : `Key with no content`)}</title>
				<path ref={keyPath} d={released} />
				<KeyText ref={textRef} x="46%" y="22%">
					<tspan>{text}</tspan>
				</KeyText>
			</KeyVector>
			{/* </KeyHover> */}
			{Thumbnail}
			{/* </div> */}
		</KeyContainer>
	)
}

const Arrows = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	height: 100%;
`

export const ArrowKey = ({ size, ...etc }) => {
	let ref = useRef()

	useEffect(() => {
		resize()
		window.addEventListener('resize', resize, false)

		return () => {
			window.removeEventListener('resize', resize, false)
		}
	})

	function resize() {
		if (ref) {
			let matchThis = document.querySelector(
				'[data-code="ArrowLeft"]'
			)
			ref.current.style.width = `${matchThis.clientWidth}px`
			ref.current.style.height = `${matchThis.clientHeight}px`
		}
	}

	return (
		<Arrows ref={ref} {...etc}>
			<DefaultKey
				text={'⇧'}
				size={200}
				eventCode={'ArrowUp'}
				style={{ height: '50%' }}
			/>
			<DefaultKey
				text={'⇩'}
				size={200}
				eventCode={'ArrowDown'}
				style={{ height: '50%' }}
			/>
		</Arrows>
	)
}
