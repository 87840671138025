import React from 'react'
import styled from 'styled-components'
import styles from "./television.module.scss"
import AspectRatio from '../../../../components/aspect'


const TvContainer = props => (
	<AspectRatio maintainHeight ratio={props.ratio || 16/9} style={{
		height: '80%',
		width: 'auto',
		...props.style
	}} className={styles.tvContainer}>{props.children}</AspectRatio>
)

const TvOuter = styled.div`
	width: 100%;
	top: 0;
	left: 0;
	height: 100%;
	padding: 2%;
`

const TvInner = styled.div`
	width: 100%;
    height: 100%;
`

const TvStand = props => (
	<svg
	className={styles.tvSvg}
	style={props.style}
		viewBox="0 0 335 88"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect x="67.5" y="0.5" width="202" height="61" />
		<path
			d="M1 81.5L49 33.5H66.5M1 81.5H334.5M1 81.5V87H334.5V81.5M334.5 81.5L286.5 33.5H270"
		/>
	</svg>
)

const Tv = ({ children, style, ratio=16/9, ...etc }) => (
	<div style={{width: '100%',...style}} {...etc}>
		<TvContainer ratio={ratio} style={{margin: '0 auto',}}>
			<TvOuter className={styles.tv}>
				<TvInner className={`${styles.tv} ${styles.tvInner}`}>
                    {children}
                </TvInner>
			</TvOuter>
			<TvStand style={{height: '20%'}} />
		</TvContainer>
		
	</div>
)

export default Tv
