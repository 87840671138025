import React from 'react'
import styles from './preview.module.scss'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import ProjectTemplate from 'Templates/project-templates/project-template'
import { IProjectPreview } from './preview.interfaces'


const shortcodes = { ProjectTemplate }

export default function ProjectPreview({ className, project, ...etc }: IProjectPreview)  {
    let img: string = project.image ? project.image.childImageSharp.fluid.src : ''

    return (
        <div className={`${styles.previewContainer} ${className}`} {...etc} data-page={project.title}>
            <div className={styles.previewContent} >
                <div className={styles.previewImage} style={{backgroundImage: `url(${img})`}} />
                <div className={styles.previewTextbox}>
                    <h1 className={styles.previewTitle}>{project.title}</h1>
                    <h2 className={styles.previewSubtitle}>{project.type}</h2>
                </div>
                <div className={styles.previewPage}>
                    <MDXProvider components={shortcodes}>
                        <MDXRenderer>{project.body}</MDXRenderer>
                    </MDXProvider>
                </div>
            </div>
        </div>
    )
}

// interface IGridProps extends HTMLAttributes<HTMLElement> {
//     itemClass: string;
//     projectEdges: IEdge[];
//     imageMap: { [key: string]: string; };
//     loader(x: object): void;
//     current: string;
//     location: any;
// }

// export const PreviewGrid = forwardRef(({ className, itemClass, projectEdges, imageMap, loader=({})=>{}, location, current, ...etc }: IGridProps, ref) => {

//     const [isExpanded, setIsExpanded] = useState(false);
//     // let isExpanded = false
//     // const setIsExpanded = (val: boolean) => isExpanded = val

//     const dur: number = 1.5

//     useImperativeHandle(ref, () => ({
//         shrink: (immediate: boolean = false): GSAPTimeline => shrink(immediate),
//         expand: (title: string, immediate: boolean = false): GSAPTimeline => expand(title, immediate),
//         toggle: (title: string): GSAPTimeline => toggle(title),
//     }), [])

//     const toggle = (title: string): GSAPTimeline => {
//         if(isExpanded) {
//             return shrink();
//         } else {
//             return expand(title);
//         }
//     }

//     const expand = (title: string, immediate: boolean = false): GSAPTimeline => {
//         console.log('Expanding...');
//         let timeline: GSAPTimeline = gsap.timeline()
//         let grid = document.querySelector(`.${styles.previewGrid}`);

//         if(grid && !isExpanded) {
//             let elt = grid.querySelector(`div[data-page=${title}]`) as HTMLDivElement

//             setIsExpanded(true)
//             let clone = elt.cloneNode(true) as HTMLDivElement
//             clone.classList.add(styles.previewActive)
//             clone.style.top = `${elt.offsetTop}px`
//             clone.style.left = `${elt.offsetLeft}px`
//             clone.style.margin = '0';
//             clone.style.cursor = 'initial';
//             grid?.appendChild(clone)
        
//             timeline.addLabel('expand')
//             .to(clone.querySelector(`.${styles.previewImage}`), {opacity: 0, duration: !immediate ? dur/2 : 0.1, ease: 'expo.out'}, 'expand')
//             .to(clone.querySelector(`.${styles.previewTextbox}`), {opacity: 0, duration: !immediate ? dur/2 : 0.1, ease: 'expo.out'}, 'expand')
//             .to(clone.querySelector(`.${styles.previewPage}`), {opacity: 1, duration: !immediate ? dur/2 : 0.1, ease: 'expo.out'}, 'expand')
//             .to(clone, {top: 0, left: 0, width: '100%', height: '100%', duration: !immediate ? dur : 0.1, ease: 'expo.out'}, 'expand')
//             .to(grid.querySelector(`.${styles.previewExit}`), {opacity: 1, duration: !immediate ? dur : 0.1, ease: 'expo.out'}, `expand+=${dur*0.9}`)
//         }

//         return timeline
//     }

//     const shrink = (immediate: boolean = false): GSAPTimeline => {
//         console.log('Shrinking...');
        
//         let timeline: GSAPTimeline = gsap.timeline()
//         let grid = document.querySelector(`.${styles.previewGrid}`);

//         if(grid && isExpanded) {
//             setIsExpanded(false)
//             let clone = grid.querySelector(`.${styles.previewActive}`)! as HTMLElement
//             let clonePage = clone.dataset.page
//             let elt = grid.querySelector(`div:not(.${styles.previewActive})[data-page="${clonePage}"]`) as HTMLDivElement
            
//             timeline.addLabel('shrink')
//             .to(grid.querySelector(`.${styles.previewExit}`), {opacity: 0, duration: !immediate ? dur/2 : 0.1, ease: 'expo.out'}, 'expand')
//             .to(clone.querySelector(`.${styles.previewImage}`), {opacity: 1, duration: !immediate ? dur/2 : 0.1, ease: 'expo.out'}, 'expand')
//             .to(clone.querySelector(`.${styles.previewTextbox}`), {opacity: 1, duration: !immediate ? dur/2 : 0.1, ease: 'expo.out'}, 'expand')
//             .to(clone.querySelector(`.${styles.previewPage}`), {opacity: 0, duration: !immediate ? dur/2 : 0.1, ease: 'expo.out'}, 'expand')
//             .to(clone, {top: elt.offsetTop, left: elt.offsetLeft, width: elt.offsetWidth, height: elt.offsetHeight, duration: !immediate ? dur : 0.1, ease: 'expo.out'}, 'expand')
//             .eventCallback('onComplete', () => {
//                 clone.remove();
//             })
//         }

//         return timeline
//     }

//     useEffect(() => {

//         let pageTitle = parameterize(window.location.href).proj
//         console.log(parameterize(window.location.href));
        

//         if (pageTitle) {
//             console.log('Expand: ', pageTitle);
//             expand(pageTitle, true);
//         } else {
//             console.log('Shrink');
//             shrink();

//         }
//     })

//     return (
//         <div className={`${className} ${styles.previewGrid}`} {...etc}>
//         {
//             projectEdges.map((edge: IEdge, index: number) => {

//                 let pageData = {
//                     title: edge.node.frontmatter.title,
//                     body: edge.node.body
//                 }

//                 const loadPageAndToggle = (e: MouseEvent<HTMLDivElement>) => {
//                     loader(pageData);
//                     expand(pageData.title);
//                 }

//                 return (
//                     <ProjectPreview onMouseDown={loadPageAndToggle} className={`${styles.previewItem} ${itemClass}`} key={edge.node.id} project={edge.node.frontmatter} body={pageData.body} />
//                 )
//             })
//         }
//         <button style={{opacity: 0}} onClick={_e => {loader({title: null, html:''})}} className={styles.previewExit}>x</button>
//         </div>
//     )
// })

