import React from 'react'

export const togglePaths = {
	gallery:
		'M 60 0 L 100 0 L 100 40 L 60 40 L 60 0 M 0 0 L 40 0 L 40 40 L 0 40 L 0 0 M 0 60 L 40 60 L 40 100 L 0 100 L 0 60 M 60 60 L 100 60 L 100 100 L 60 100 L 60 60',
	keyboard:
		'M 10 15 L 90 15 L 95 65 L 5 65 L 10 15 M 10 15 L 5 25 L 0 100 L 5 65 L 10 15 M 5 65 L 95 65 L 100 100 L 0 100 L 5 65 M 95 65 L 100 100 L 95 25 L 90 15 L 95 65',
}

export default function XPToggle({ className, ...etc }) {
	return (
		<svg
			{...etc}
			id="toggleViewButton"
			className={className}
			viewBox="-5 -5 110 110"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				id="toggleViewButtonPath"
				d={togglePaths.gallery}
			/>
		</svg>
	)
}
