import React, {
	useState,
	useLayoutEffect,
	useEffect,
	useImperativeHandle,
	forwardRef,
} from 'react'
// @ts-ignore
import styles from './preview-grid.module.scss'
// @ts-ignore
import previewStyles from './preview.module.scss'
import ProjectPreview from './preview'
import { IPreviewGrid } from './preview.interfaces'
import FSToggle from '../../../../utils/animations/fullscreen'

//    _____       _             __
//   |_   _|     | |           / _|
//     | |  _ __ | |_ ___ _ __| |_ __ _  ___ ___  ___
//     | | | '_ \| __/ _ \ '__|  _/ _` |/ __/ _ \/ __|
//    _| |_| | | | ||  __/ |  | || (_| | (_|  __/\__ \
//   |_____|_| |_|\__\___|_|  |_| \__,_|\___\___||___/
//
//

interface IToggle {
	elt?: HTMLElement | Element
	tog?: FSToggle
}

//     _____                                             _
//    / ____|                                           | |
//   | |     ___  _ __ ___  _ __   ___  _ __   ___ _ __ | |_
//   | |    / _ \| '_ ` _ \| '_ \ / _ \| '_ \ / _ \ '_ \| __|
//   | |___| (_) | | | | | | |_) | (_) | | | |  __/ | | | |_
//    \_____\___/|_| |_| |_| .__/ \___/|_| |_|\___|_| |_|\__|
//                         | |
//                         |_|

const PreviewGrid = forwardRef(
	(
		{
			projects,
			open = '',
			duration = 0.25,
			onExpand = () => {},
			className,
			containerClassName,
			style,
		}: IPreviewGrid,
		ref
	) => {
		const defaultProject: { title: string } = {
			title: '',
		}

		const [current, setCurrent] = useState(defaultProject)
		const [toggle, setToggle] = useState<IToggle>({})

		useLayoutEffect(() => {
			if (document.querySelector(`#${getId(open)}`)) {
				expand(open, true)
			}
		}, [open])

		useEffect(() => {
			toggle.tog?.makeFullscreen()
		}, [toggle])

		useImperativeHandle(
			ref,
			() => ({
				shrink: (title: string) => shrink(title),
			}),
			[]
		)

		function expand(title: string, immediate: boolean = false) {
			if (title !== current.title) {
				setCurrent({ title: title })

				let idQuery = `#${getId(title)}`

				let elt = document.querySelector(idQuery)!
				elt.classList.add(previewStyles.active)
				if (immediate) {
					elt.classList.add(
						previewStyles.immediate
					)
				}

				let fsTog = new FSToggle(idQuery, `.xp-full-view`, {
					zIndex: 1000,
					immediate: immediate,
					duration: duration,
					ease: 'power3.out',
					isGrid: true,
					onExpandComplete: () => {
						onExpand()
					},
				})
				setToggle({ elt: elt, tog: fsTog })
			}
		}

		const [willShrink, setWillShrink] = useState(false)
		function shrink(_title: string) {
			setWillShrink(true)
		}
		useEffect(() => {
			if (current.title !== '') {
				toggle.elt?.classList.remove(
					previewStyles.active
				)
				toggle.tog?.stopFullscreen()
				setCurrent({ title: '' })
			}
			if (willShrink) {
				setWillShrink(false)
			}
		}, [willShrink])

		return (
			<div className={`${styles.gridContainer} ${containerClassName} xp-full-view`} style={style}>
			<div
				className={`${styles.grid} ${className}`}
			>
				{projects.map(project => {
					const { node: proj } = project
					const projData = {
						body: proj.body,
						...proj.frontmatter,
					}

					return (
						<ProjectPreview
							key={proj.id}
							id={getId(
								projData.title
							)}
							className={
								`${styles.project} project-preview`
							}
							onClick={_e =>
								expand(
									projData.title
								)
							}
							project={projData}
						/>
					)
				})}
			</div>
			</div>
		)
	}
)

// Preview needs:
//   image
//   title
//   type

function getId(title: string): string {
	return `proj---${title}`.replace(/\s/g, '-')
}

export default PreviewGrid
