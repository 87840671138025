import gsap from 'gsap'

interface IFSOptions {
	zIndex?: number
	isGrid?: boolean
	immediate?: boolean
	duration?: number
	ease?: string
	onExpand?: Function
	onShrink?: Function
	onExpandComplete?: Function
	onShrinkComplete?: Function
}
interface IElementData {
	element: HTMLElement
	bounds: () => DOMRect
}

export default class FSToggle {
	elt: IElementData
	parent: IElementData
	dummy: IElementData
	options: IFSOptions
	isFullscreen: boolean
	isAnimating: boolean =false

	constructor(
		elt: HTMLElement | string,
		parent: HTMLElement | string,
		options: IFSOptions
	) {
		let eltElement = (typeof elt === 'string'
			? document.querySelector(elt)
			: elt) as HTMLElement
		let parentElement = (typeof parent === 'string'
			? document.querySelector(parent)
			: parent) as HTMLElement

		this.isFullscreen = false

		this.elt = {
			element: eltElement,
			bounds: eltElement.getBoundingClientRect.bind(
				eltElement
			),
		}

		this.parent = {
			element: parentElement,
			bounds: parentElement.getBoundingClientRect.bind(
				parentElement
			),
		}

		let dummyElt = document.createElement('div')
		dummyElt.style.cssText = `
		${!options.isGrid && 'position: absolute;top: 0;left: 0;'}
		${!options.isGrid && 'width: 100%;height: 100%;'}
        pointer-events: none;
		zIndex: -10000;
		background: transparent;
		border: none;
		outline: none;
		opacity: 0;
		`

		eltElement.classList.forEach(eltClass =>
			dummyElt.classList.add(eltClass)
		)

		eltElement.insertAdjacentElement('afterend', dummyElt)

		this.dummy = {
			element: dummyElt,
			bounds: dummyElt.getBoundingClientRect.bind(dummyElt),
		}

		this.options = options
	}

	makeFullscreen(callback?: Function) {
		if (this.options.immediate) {
			if (this.options.onExpand) {
				this.options.onExpand()
			}

			this.parent.element.appendChild(this.elt.element)
			this.elt.element.style.cssText = `
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: ${this.options?.zIndex ?? 1};
			`

			if (this.options.onExpandComplete) {
				this.options.onExpandComplete()
			}

			return
		}

		const { top, left, width, height } = this.elt.bounds()

		// === Animate === //
		if(this.isAnimating) {
			return;
		}
		this.isAnimating = true;
		let timeline = gsap.timeline()
		timeline.add(() =>
			this.parent.element.appendChild(this.elt.element)
		)
		timeline.fromTo(
			this.elt.element,
			{
				position: 'absolute',
				top: `${top - this.parent.bounds().top}px`,
				left: `${left - this.parent.bounds().left}px`,
				width: `${width}px`,
				height: `${height}px`,
				zIndex: `${this.options?.zIndex ?? 1}`,
			},
			{
				position: 'absolute',
				top: `0`,
				left: `0`,
				width: `100%`,
				height: `100%`,
				duration: this.options?.duration ?? 1,
				ease: this.options?.ease ?? 'power2.out',
			}
		)
			.eventCallback('onComplete', () => {
				this.isAnimating = false;
				if (this.options.onExpandComplete) {
					this.options.onExpandComplete()
				}

				if (callback) {
					callback();
				}
			})
			.eventCallback('onStart', () => {
				if (this.options.onExpand) {
					this.options.onExpand()
				}
			})
	}

	stopFullscreen(callback?: Function) {
		if (this.options.duration === 0) {
			if (this.options.onShrink) {
				this.options.onShrink()
			}

			this.dummy.element.parentNode?.insertBefore(
				this.elt.element,
				this.dummy.element
			)
			if (this.options.isGrid) {
				this.elt.element.style.cssText = `
					position: initial;
					top: initial;
					left: initial;
					width: initial;
					height: initial;
					z-index: initial;
				`
			} else {
				this.elt.element.style.cssText = `
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: initial;
				`
			}

			if (this.options.isGrid) {
				this.cleanup()
			}

			if (this.options.onShrinkComplete) {
				this.options.onShrinkComplete()
			}

			return
		}

		// === Animate === //
		if(this.isAnimating) {
			return;
		}
		this.isAnimating = true;
		let timeline = gsap.timeline()
		timeline.fromTo(
			this.elt.element,
			{
				position: `absolute`,
				top: `0`,
				left: `0`,
				width: `${this.elt.bounds().width}px`,
				height: `${this.elt.bounds().height}px`,
				zIndex: `${this.options?.zIndex ?? 1}`,
			},
			{
				top: `${this.dummy.bounds().top -
					this.parent.bounds().top}px`,
				left: `${this.dummy.bounds().left -
					this.parent.bounds().left}px`,
				width: `${this.dummy.bounds().width}px`,
				height: `${this.dummy.bounds().height}px`,
				duration: this.options?.duration ?? 1,
				ease: this.options?.ease ?? 'power2.out',
			}
		).add(() =>
			this.dummy.element.insertAdjacentElement(
				'beforebegin',
				this.elt.element
			)
		)
		if (this.options.isGrid) {
			timeline.to(this.elt.element, {
				position: 'initial',
				top: 'initial',
				left: 'initial',
				width: 'initial',
				height: 'initial',
				zIndex: 'initial',
				duration: 0,
			})
		} else {
			timeline.to(this.elt.element, {
				position: 'absolute',
				top: '0',
				left: '0',
				width: '100%',
				height: '100%',
				zIndex: 'initial',
				duration: 0,
			})
		}
		timeline.eventCallback('onComplete', () => {
			this.isAnimating = false;
			if (this.options.isGrid) {
				this.cleanup()
			}

			if (this.options.onShrinkComplete) {
				this.options.onShrinkComplete()
			}

			if (callback) {
				callback()
			}
		}).eventCallback('onStart', () => {
			if (this.options.onShrink) {
				this.options.onShrink()
			}
		})
	}

	toggle(callback?: Function) {
		this.toggleFullscreen(callback)
	}

	toggleFullscreen(callback?: Function) {
		if (this.isFullscreen) {
			this.stopFullscreen(callback)
			this.isFullscreen = false
		} else {
			this.makeFullscreen(callback)
			this.isFullscreen = true
		}
	}

	cleanup() {
		this.dummy.element.remove()
	}
}
