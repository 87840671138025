import React from 'react'
import DefaultKey, { ArrowKey } from './keys'
import styled from 'styled-components'
import styles from './keyboard.module.scss'
import AspectRatio from 'Components/aspect'

const KeyRow = styled.div`
width: 100%;
display: inline-flex;
justify-content: center;
height: 5vh;
`

const Keyboard = ({ className, style, keyMap, imageMap, pagesMap, loader=(page)=>{}, pageData={} }) => {

    const onSelect = code => {
        if(code in pageData) {
            loader(pageData[code]);
        }
    }

    return (
		<AspectRatio maintainHeight ratio={3.77} style={style} className={`${styles.keyboard} ${className}`}>
        {
            keyMap.map(edge => {
                return (
                    <KeyRow className={'keyboard-row'} key={edge.node.id}>
                        {
                            edge.node.row.map((item, index) => {

                                // === Get Project === //
                                let img = null
                                let title = ''
                                // --- Check if Project Associated with Key --- //
                                if(item.project) {
                                    // --- Get Project Thumbnail --- //
                                    let imgEdge = imageMap.find(edge => edge.node.relativeDirectory === item.project)
                                    let imgSrc
                                    if(imgEdge) {
                                        // Create the image from the publicURL source
                                        imgSrc = imgEdge.node.publicURL;
                                        img = <img src={imgSrc} alt={`Thumbnail for ${item.project}`} style={{width: '100%', height: '100%'}}/>
                                    }

                                    // --- Get Subpage --- //
                                    let pageEdge = pagesMap.find(edge => edge.node.frontmatter.project === item.project)

                                    // --- Get Project Title --- //
                                    title = item.project
                                    if(pageEdge) {
                                        if(pageEdge.node.frontmatter.title.length > 0) {
                                            title = pageEdge.node.frontmatter.title
                                        }

                                        pageData[item.code] = {
                                            dir: item.project,
                                            title: title,
                                            frontmatter: pageEdge.node.frontmatter,
                                            mdx: pageEdge.node.body,
                                            img: imgSrc
                                        };
                                        
                                    }
                                    
                                }
                                if(item.code === 'UpDown') {
                                    return (<ArrowKey className={'keyboard-key'} style={{flex: 0, padding: '0 5px', height: '50%'}} key={`${edge.node.id}--${index}`} size={item.size} />)
                                } else {
                                    return (
                                        <DefaultKey className={'keyboard-key'} style={{flex: 0, padding: '0 5px'}} key={`${edge.node.id}--${index}`} text={item.text} eventCode={item.code} size={item.size} title={title} content={img} onSelect={onSelect}/>
                                    )
                                }
                            })
                        }
                    </KeyRow>
                )

            })
            }
		</AspectRatio>
		)
}

export default Keyboard
